import { IntlShape } from 'react-intl';
import { AccommodationTypeEnum, RentalUnitTypeEnum } from '../../generated/graphql';

export const defaultAccommodationTypeOptions = (intl: IntlShape) => {
    const { formatMessage } = intl;
    return [
        { value: AccommodationTypeEnum.Hotel, label: formatMessage({ defaultMessage: 'Hotel' }) },
        {
            value: AccommodationTypeEnum.Location,
            label: formatMessage({ defaultMessage: 'Vakantiewoning' }),
            rentalUnitsType: RentalUnitTypeEnum.Home,
        },
        { value: AccommodationTypeEnum.BedAndBreakfast, label: formatMessage({ defaultMessage: 'Bed & Breakfast' }) },
        { value: AccommodationTypeEnum.Camping, label: formatMessage({ defaultMessage: 'Camping' }) },
        { value: AccommodationTypeEnum.BungalowPark, label: formatMessage({ defaultMessage: 'Bungalowpark' }) },
        {
            value: AccommodationTypeEnum.Location,
            label: formatMessage({ defaultMessage: 'Boot' }),
            rentalUnitsType: RentalUnitTypeEnum.Boat,
        },
    ];
};

export const defaultRentalUnitTypesOptions = (intl: IntlShape) => {
    const { formatMessage } = intl;
    return [
        { value: RentalUnitTypeEnum.Room, label: formatMessage({ defaultMessage: 'Kamer' }) },
        { value: RentalUnitTypeEnum.Home, label: formatMessage({ defaultMessage: 'Gehele woning/bungalow/chalet' }) },
        { value: RentalUnitTypeEnum.CampingPitch, label: formatMessage({ defaultMessage: 'Kampeerplaats' }) },
        { value: RentalUnitTypeEnum.Tent, label: formatMessage({ defaultMessage: '(Ingerichte) tent' }) },
        { value: RentalUnitTypeEnum.Bed, label: formatMessage({ defaultMessage: 'Los bed in gedeelde ruimte' }) },
        { value: RentalUnitTypeEnum.CamperSpot, label: formatMessage({ defaultMessage: 'Camperplaats' }) },
        { value: RentalUnitTypeEnum.Boat, label: formatMessage({ defaultMessage: 'Boot' }) },
    ];
};
